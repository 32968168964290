@charset "utf-8";
/*
font-family: 'Noticia Text', serif;
font-family: 'Nunito Sans', sans-serif;
*/
@import url('https://fonts.googleapis.com/css2?family=Noticia+Text:wght@700&family=Nunito+Sans:ital,wght@0,400;0,600;1,400;1,600&display=swap');
// Define defaults for each variable.



$base-font-family: 'Nunito Sans', sans-serif !default;
$base-font-size:   18px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 1.125 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     80px !default;

$text-color:       #397BDF !default;
$background-color: #EBF1FC !default;
$brand-color:      #397BDF !default;

$grey-color:       #F3F3F3 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    960px !default;

$on-palm:          600px !default;
$on-laptop:        960px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
