/**
 * Site header
 */
.site-header {
  padding: 80px 0 0 0;

  // Positioning context for the mobile navigation icon
  position: relative;

  @include media-query($on-palm) {
    padding: 30px 0 0 0;
  }
}

.logo {
  text-align: center;
}

h1 {
  font-family: 'Noticia Text', serif;
  color: $brand-color;
  line-height: 1.2;
  text-align: center;
  font-weight: 700;
}
h2 { font-family: 'Noticia Text', serif; font-size: 36px; }
h3 { font-family: 'Noticia Text', serif; font-size: 24px; }

.site-title {
  @include relative-font-size(1.625);
  display: inline-block;
  margin-left: -50px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 0;

  &,
  &:visited {
    color: $grey-color-dark;
  }
  svg { display: block; 
    width: 426px;
  }
  @include media-query($on-palm) {
    margin-left: 0;
    svg { display: block; 
      width: 300px;
    } 
  }
}

.site-nav {
  line-height: 1; display: flex; justify-content: center;
  background-color: $brand-color; margin-top: 60px;
  .page-link {
    color: #FFFBF4;
    line-height: $base-line-height;
    font-size: $base-font-size * 1.2;
    font-weight: 600;
    padding: 13px 0 10px 0;
    margin: 0 40px;
    border-bottom: 3px solid $brand-color;
    &:hover {
      text-decoration: none;
      border-bottom: 3px solid #FFF4E3;
    }
  }
  

  @include media-query($on-palm) {

    margin-top: 20px;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      font-size: 18px;
      padding: 10px 0 7px 0;
      margin: 0 30px;
    }
  }
}


.banner {
  background-color: #FFF4E3;
  background: url(/assets/images/banner.svg) transparent repeat center center;
  background-size: cover;
  .wrapper {
    padding: 100px 40px;
    text-align: center;
    h1 {
      font-family: 'Noticia Text', serif;
      color: #397BDF;
      line-height: 1.5;
      text-align: center;
      font-weight: 700;
      font-size: 36px;
      margin: 0;

    }
    h2 {  margin: 40px 0 0 0; font-size: 24px; max-width: 700px; margin-left: auto; margin-right: auto; }
    p {
      margin: 40px auto 0 auto; max-width: 600px; color: #02204D;
    }
  }
  @include media-query($on-laptop) {
    background-size: cover;
    .wrapper {
      padding: 40px 20px;
      h1 {
        font-size: 28px; line-height: 1.4;
      }
      h2 {
        font-size: 20px; margin-bottom: 20px;
      }
      p {
        margin-top: 20px; font-size: 16px;
      }
    }
  }
}

#post {
  .banner {
    .wrapper {
      padding-bottom: 200px;
    }
  }
  @include media-query($on-palm) {
    .banner {
      .wrapper {
        padding-bottom: 40px;
      }
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  padding: 100px 0 40px 0;
  background-color: #397BDF;
  color: #FFFBF4;
  svg .letter {
    fill: #FFF4E3;
  }
  nav.footer-col {
    margin: 0 auto; display: block; max-width: 100%;
    ul {
      margin: 0 auto; justify-content: center;
      display: flex; list-style: none;
      li { margin: 0 30px; list-style: none; }
      a { color: #FFF4E3; }
    }
  }
  @include media-query($on-palm) {
    padding: 40px 0 40px 0;
  }
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: 20px;
  text-align: center;
  .site-title { margin-left: 0;}
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
 text-align: center; margin: 0 auto;
 max-width: 600px;
 p { margin-bottom: 80px; color: #FFF4E3; }
}

.copyright {
  font-size: 12px; color: #91ade1;
  p { margin: 20px 0 0 0; color: #91ade1; }
}

/**
 * Page content
 */
.page-content {
  padding: 100px 0;
  flex: 1;
  @include media-query($on-palm) {
    padding: 40px 0 0 0;
  }
}

.page-heading {
  text-align: center;
  margin-bottom: 40px;
  @include media-query($on-palm) {
    font-size: 28px;
  }
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  > li {
    margin-bottom: 3.5%;
    flex: 31% 0 0;
    .post-wrap {
      background-color: #FFF;
      border: 2px solid #CDDFFF;
      border-radius: 5px;
      padding: 20px;
      position: relative;
      &:hover {
        box-shadow: -18px 30px 40px -40px #101010, 18px 30px 40px -40px #242122;
      }
      .post-link {
        position: absolute;
        top: 0;
        border: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 20px;
        &:hover {
          border: none; text-decoration: none;
        }
      }
      .img-wrap {
        margin-top: 50px;
      }
    }
    &:nth-child(2) { padding: 0 3.5%; };
    &:nth-child(5) { padding: 0 3.5%; };
    &:nth-child(8) { padding: 0 3.5%; };
    &:nth-child(11) { padding: 0 3.5%; };
    &:nth-child(14) { padding: 0 3.5%; };
    &:nth-child(17) { padding: 0 3.5%; };
    &:nth-child(20) { padding: 0 3.5%; };
    &:nth-child(23) { padding: 0 3.5%; };
    &:nth-child(26) { padding: 0 3.5%; };
    &:nth-child(29) { padding: 0 3.5%; };
    &:nth-child(32) { padding: 0 3.5%; };
    &:nth-child(35) { padding: 0 3.5%; };

    h3 { margin-bottom: 20px; }
    .img-wrap-full {
      img { border-radius: 5px;}
      /*margin: 0 -22px;*/
    }
    .post-link-btn {
      position: relative; z-index: 1;
      margin-top: 20px; background-color: #397BDF; border-radius: 5px; padding: 7px 20px; color: #FFFBF4; display: inline-block;
      &:hover {
        background-color: #1b55ac; text-decoration: none;
      }
    }
  }

  @include media-query($on-palm) {
    > li {
      flex: 100% 0 0;
      margin-bottom: 20px;
      &:nth-child(2) { padding: 0; };
      &:nth-child(5) { padding: 0; };
      &:nth-child(7) { padding: 0; };
      &:nth-child(10) { padding: 0; };
      &:nth-child(13) { padding: 0; };
      &:nth-child(16) { padding: 0; };
    }
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
}

/**
 * Posts
 */


.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
  @include media-query($on-palm) { 
    margin-bottom: 40px;
  }
}

article {
  background-color: #FFF;
  border: 2px solid #CDDFFF;
  border-radius: 5px;
  margin-top: -180px;
  color: #02204D;
  
  p { line-height: 150%; }
 h4 {
    font-family: 'Noticia Text', serif;
  }
  h2, h3, h4, h5 {
    font-family: 'Noticia Text', serif; margin: 40px 0 20px 0;
  }

h2, h3, h4, h5, h6, p, blockquote, pre, ul, ol, dl, figure, .highlight {
    margin-bottom: 20px;
}

ul, ol {
  margin-left: 15px; margin-bottom: 0;
  li { margin-bottom: 20px; }
}

  .post {
    display: grid;
    max-width: 1000px;
    grid-template-columns: 130px auto 130px;
    grid-gap: 10px 20px;
  }
  
  .post > * {
    grid-column: 2 / -2;
  }
  
  .post > figure {
    margin: 0;
    grid-column: 1 / -1;
  }
  
  
  figcaption {
    font-size: 12px;
  }
  
  .post > blockquote {
    grid-column: 1 / -1;
    font-size: 60px;
    margin: 0;
    font-style: italic;
    text-align: center;
  }
  
  .tip {
    background: #EBEBEB;
    padding: 10px;
    grid-row: span 5;
    align-self: start;
  }
  
  .tip-left {
    grid-column: 1 / span 1;
    text-align: right;
    border-right: 2px solid var(--yellow);
  }
  
  .tip-right {
    grid-column: span 1 / -1;
    border-left: 2px solid var(--yellow);
  }

  .post-image {
    margin: -2px; position: relative; z-index: 1;
    img {
      box-shadow: 0 2px 10px #afafaf;
      -webkit-border-top-left-radius: 5px;
-webkit-border-top-right-radius: 5px;
-moz-border-radius-topleft: 5px;
-moz-border-radius-topright: 5px;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
    }
  }

  .glance {
    background-color: #F4F7FB;
    margin: 0 -150px 100px -150px;
    padding: 100px 150px;
    h2 { margin-top: 0;}
    ul { 
      margin: 0;
      padding: 0;
      list-style-position: inside;
    }
    p { margin: 20px 0 0 0; padding: 0;}
  }
  
  @include media-query($on-palm) {
    border: none;
    margin-top: -40px;
    .post {
      display: block; padding: 0 20px 20px 20px;
    }

    .glance {
        margin: 0 -20px;
        padding: 40px 20px;
    }

  }

}

#post .page-content {
  .wrapper {
    padding: 0;
  }
}

.page-content {
  article {
    h2 {
      font-size: 32px;
    }
    h3 {
      font-size: 28px;
    }

    h4 {
      font-size: 24px;
    }
    h5 {
      font-size: 20px;
    }
  }
}

.sigle-page {
  margin-top: 80px;
}
